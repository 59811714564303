import React, { useEffect, useRef, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import c from './Map.module.scss';
import { DealershipEvent } from './App';

import mapboxgl, { Map } from 'mapbox-gl';

mapboxgl.accessToken = 'pk.eyJ1IjoidmlnYmpvcm4iLCJhIjoiY2xibTNyd2wyMDNwejNvcnU2MHhjNTExdSJ9.OczCHELNM2q4WzuU64Yq7g';
// mapboxgl.accessToken =
//   'pk.eyJ1IjoidGhpcy1pcy1icmlsbGlhbnQiLCJhIjoiY2xiZ2tvd3djMGd6eDNzcndqNzA2MDJwciJ9.bCzZqofiCmwva9-n4V3ZvQ';

const mobileCameraDefault = {
  center: [10.79, 63.12] as mapboxgl.LngLatLike,
  zoom: 4.07,
  bearing: 15.61,
  pitch: 52.59,
};

const tabletPortraitCameraDefault = {
  center: [10.79, 64.05] as mapboxgl.LngLatLike,
  zoom: 4.29,
  bearing: 15.61,
  pitch: 52.59,
};

const tabletLandscapeCameraDefault = {
  center: [11.15, 63.46] as mapboxgl.LngLatLike,
  zoom: 4.51,
  bearing: 15.61,
  pitch: 52.59,
};

const desktopCameraDefault = {
  center: [10.71, 62.91] as mapboxgl.LngLatLike,
  zoom: 4.51,
  bearing: 15.61,
  pitch: 52.59,
};

const bigDesktopCameraDefault = {
  center: [14.35, 62.28] as mapboxgl.LngLatLike,
  zoom: 4.79,
  bearing: 19.2,
  pitch: 46.5,
};

const defaultCameraPositionFullyPortrait = {
  center: [14.79, 64.9] as mapboxgl.LngLatLike,
  zoom: 5.12,
  bearing: 22,
  pitch: 50,
};

const defaultCameraPositionFullyLandscape = {
  center: [13.71, 62.01] as mapboxgl.LngLatLike,
  zoom: 5.01,
  bearing: 15.2,
  pitch: 53.5,
};

const isTabletPortraitAndUp = window.matchMedia('(min-width: 600px)').matches;
const isTabletLandscapeAndUp = window.matchMedia('(min-width: 900px)').matches;
const isDesktopUp = window.matchMedia('(min-width: 1200px)').matches;
const isBigDesktopUp = window.matchMedia('(min-width: 1800px)').matches;

let defaultCameraPosition = mobileCameraDefault;

if (isTabletPortraitAndUp) defaultCameraPosition = tabletPortraitCameraDefault;
if (isTabletLandscapeAndUp) defaultCameraPosition = tabletLandscapeCameraDefault;
if (isDesktopUp) defaultCameraPosition = desktopCameraDefault;
if (isBigDesktopUp) defaultCameraPosition = bigDesktopCameraDefault;

if (!!window.fully)
  defaultCameraPosition = isBigDesktopUp ? defaultCameraPositionFullyLandscape : defaultCameraPositionFullyPortrait;

const startingCameraPosition = {
  center: [106.1, 63.1] as mapboxgl.LngLatLike,
  zoom: 1,
  bearing: 19,
  pitch: 46.5,
};

const intervalDelay = 5 * 1000;
const animationDuration = 5 * 60 * 1000;
const radiusStartValue = 5;
const radiusMaxValue = 25;
const opacityStartValue = 0.5;
const opacityMinValue = 0.1;

const MapContainer: React.FC<{ visibleDealershipEvents: DealershipEvent[] }> = ({ visibleDealershipEvents }) => {
  const mapContainer = useRef(null);
  const map = useRef<Map | null>(null);

  const [isFollowFlightEnabled, setIsFollowFlightEnabled] = useState(false);

  useEffect(() => {
    if (!!map.current) return; // initialize map only once

    map.current = new Map({
      container: mapContainer.current || '',
      style: 'mapbox://styles/vigbjorn/clbm3s742000f14phqjhuojz2?optimize=true',
      ...startingCameraPosition,
    });

    map.current.on('load', () => {
      // @ts-ignore
      map.current?.flyTo({ ...defaultCameraPosition, preloadOnly: true });
      setTimeout(() => {
        map.current?.flyTo({ ...defaultCameraPosition, duration: 8000 });
      }, 2000);
    });
  }, []);

  useEffect(() => {
    visibleDealershipEvents.forEach((event) => {
      if (!map.current) return;
      if (!map.current.getLayer(event.id)) {
        map.current?.addLayer({
          id: event.id,
          type: 'circle',
          source: {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: event.coordinates,
                  },
                  properties: [],
                },
              ],
            },
          },
          paint: {
            'circle-color': event.color,
            'circle-radius': radiusStartValue,
            'circle-opacity': opacityStartValue,
            'circle-radius-transition': {
              duration: 0,
            },
            'circle-opacity-transition': {
              duration: 0,
            },
          },
        });

        setTimeout(() => {
          if (!map.current) return;
          map.current.removeLayer(event.id);
          map.current.removeSource(event.id);
        }, animationDuration + 5 * 1000);
      }
    });

    if (isFollowFlightEnabled && visibleDealershipEvents.length > 0) {
      const newestDealership = visibleDealershipEvents[visibleDealershipEvents.length - 1];
      // @ts-ignore
      map.current?.flyTo({ center: newestDealership?.coordinates, zoom: 6, duration: 3000 });
    }
  }, [visibleDealershipEvents, isFollowFlightEnabled]);

  useEffect(() => {
    const updateCircle = (event: DealershipEvent) => {
      const currentCircleRadius = map.current?.getPaintProperty(event.id, 'circle-radius');
      const currentCircleOpacity = map.current?.getPaintProperty(event.id, 'circle-opacity');

      map.current?.setPaintProperty(event.id, 'circle-radius', Math.min(currentCircleRadius + 0.33, radiusMaxValue));
      map.current?.setPaintProperty(
        event.id,
        'circle-opacity',
        Math.max(currentCircleOpacity - 0.007, opacityMinValue)
      );
    };

    visibleDealershipEvents.forEach(updateCircle);

    const interval = setInterval(() => {
      visibleDealershipEvents.forEach(updateCircle);
    }, intervalDelay);

    return () => {
      clearTimeout(interval);
    };
  }, [visibleDealershipEvents]);

  return (
    <div>
      {/*<button*/}
      {/*  className={c.fitToNorway}*/}
      {/*  onClick={() => {*/}
      {/*    if (!map.current) return;*/}

      {/*    map.current?.fitBounds(*/}
      {/*      [*/}
      {/*        [4.9, 57.8],*/}
      {/*        [31.0, 71.1],*/}
      {/*      ],*/}
      {/*      { duration: 3000 }*/}
      {/*    );*/}
      {/*  }}*/}
      {/*>*/}
      {/*  Tilpass til Norge*/}
      {/*</button>*/}

      {/*<button*/}
      {/*  className={c.resetPitch}*/}
      {/*  onClick={() => {*/}
      {/*    if (!map.current) return;*/}

      {/*    map.current.resetNorthPitch({ duration: 3000 });*/}
      {/*  }}*/}
      {/*>*/}
      {/*  Tilbakestill vinkel*/}
      {/*</button>*/}

      <button className={c.toggleFollowFlight} onClick={() => setIsFollowFlightEnabled((prevState) => !prevState)}>
        {isFollowFlightEnabled ? 'Zoomer inn på nyeste hendelse 🚀' : 'Holder kartet stille'}
      </button>

      <button
        className={c.resetMapButton}
        onClick={() => {
          if (!map.current) return;

          map.current.flyTo({ ...defaultCameraPosition, duration: 3000 });
        }}
      >
        Tilbakestill kartet
      </button>

      <div ref={mapContainer} className={c.mapContainer} />
    </div>
  );
};

export default MapContainer;
